import React from "react";
import { Unstable_Grid2 as Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";

export default function Leasing({
  highlights,
  buildingSpecifications,
  siteMapData,
  cmsEndpoint,
  ...props
}) {
  // React.useEffect(() => {
  //   siteMapData.Image[0].url = cmsEndpoint + siteMapData.Image[0].url;
  // }, []);

  return (
    <>
      <Wrapper
        container
        justifyContent={"space-evenly"}
        alignItems={"center"}
        backgroundColor="#F5F6F6"
        pt={{ xs: 2, md: 6 }}
        pb={{ xs: 2, md: 6 }}
        mb={{ xs: 4, md: 8 }}
        pl={{ md: 8 }}
        // pr={{ md: 8 }}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid
          item
          xs={12}
          md={4}
          pl={{ xs: 4, sm: 10, md: 0 }}
          pr={{ xs: 4, sm: 10, md: 0 }}
          pt={{ xs: 4, sm: 0 }}
        >
          <Typography variant={"h1"} color="primary.main" align="left">
            {highlights.title}
          </Typography>

          <ReactMarkdown>{highlights.notes}</ReactMarkdown>
        </Grid>
        <Grid item xs={12} md={8} pl={{ xs: 4, sm: 10 }} pr={{ xs: 4, sm: 10 }}>
          <img
            src={`${cmsEndpoint}${siteMapData?.Image[0].url}`}
            alt={siteMapData?.alternativeText}
            style={{ width: "100%", height: "auto" }}
          />
        </Grid>
      </Wrapper>
      {buildingSpecifications.building1.name && (
        <Typography
          variant="h1"
          color="primary.main"
          align="left"
          pl={{ xs: 4, sm: 10 }}
          pr={{ xs: 4, sm: 10 }}
        >
          Phase 1
        </Typography>
      )}
      {Object.keys(buildingSpecifications).map((building, index) => {
        return (
          <Grid
            key={building}
            container
            spacing={"1px"}
            pl={{ xs: 4, sm: 10 }}
            pr={{ xs: 4, sm: 10 }}
          >
            {buildingSpecifications[building].phase === 1 && (
              <Grid
                component={Typography}
                variant="h2"
                color="primary.main"
                align="left"
                xs={12}
                mb={2}
                mt={2}
              >
                {buildingSpecifications[building].name}
              </Grid>
            )}
            {buildingSpecifications[building].phase === 1 &&
              buildingSpecifications[building].data.map((spec, index) => {
                return (
                  <Grid key={spec[0] + spec[1] + index} xs={12} sm={2} md={2} mb={{ xs: 1, md: 4 }}>
                    <Grid container flexDirection={"column"}>
                      <Grid
                        p={{ xs: 0.5, sm: 1, md: 2 }}
                        backgroundColor="grey.backgroundGrey"
                        color="white"
                        fontWeight="bold"
                        whiteSpace="nowrap"
                        fontSize={{ sm: "x-small", md: "smaller", lg: "initial" }}
                        width={"100%"}
                      >
                        {spec[0]}
                      </Grid>
                      <Grid
                        width={"100%"}
                        p={{ xs: 0.5, md: 2 }}
                        fontWeight={spec[0] === "Building Area" ? "bold" : null}
                        color={spec[0] === "Building Area" ? "primary.main" : null}
                      >
                        {spec[0] === "Building Area" ? spec[1].toLocaleString() : spec[1]}
                        {spec[0] === "Building Area" ? " SF" : null}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        );
      })}
      {buildingSpecifications.building1.name && (
        <Typography
          variant="h1"
          color="primary.main"
          align="left"
          pl={{ xs: 4, sm: 10 }}
          pr={{ xs: 4, sm: 10 }}
        >
          Phase 2
        </Typography>
      )}
      {Object.keys(buildingSpecifications).map((building, index) => {
        return (
          <Grid
            key={building}
            container
            spacing={"1px"}
            pl={{ xs: 4, sm: 10 }}
            pr={{ xs: 4, sm: 10 }}
          >
            {buildingSpecifications[building].phase === 2 && (
              <Grid
                component={Typography}
                variant="h2"
                color="primary.main"
                align="left"
                xs={12}
                mb={2}
                mt={2}
              >
                {buildingSpecifications[building].name}
              </Grid>
            )}
            {buildingSpecifications[building].phase === 2 &&
              buildingSpecifications[building].data.map((spec, index) => {
                return (
                  <Grid key={spec[0] + spec[1] + index} xs={12} sm={2} md={2} mb={{ xs: 1, md: 4 }}>
                    <Grid container flexDirection={"column"}>
                      <Grid
                        p={{ xs: 0.5, sm: 1, md: 2 }}
                        backgroundColor="grey.backgroundGrey"
                        color="white"
                        fontWeight="bold"
                        whiteSpace="nowrap"
                        fontSize={{ sm: "x-small", md: "smaller", lg: "initial" }}
                        width={"100%"}
                      >
                        {spec[0]}
                      </Grid>
                      <Grid
                        width={"100%"}
                        p={{ xs: 0.5, md: 2 }}
                        fontWeight={spec[0] === "Building Area" ? "bold" : null}
                        color={spec[0] === "Building Area" ? "primary.main" : null}
                      >
                        {spec[0] === "Building Area" ? spec[1].toLocaleString() : spec[1]}
                        {spec[0] === "Building Area" ? " SF" : null}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        );
      })}
    </>
  );
}
const Wrapper = styled(Grid)`
  ul {
    text-align: left;
    line-height: 2rem;
    li::marker {
      color: red; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      font-size: 1.5rem;
      position: absolute;
    }
  }
`;
