import React from "react";

import styled from "@emotion/styled";

import { Unstable_Grid2 as Grid, Link } from "@mui/material";
import { ReactComponent as Logo } from "./svg/logo_enright.svg";

import GWLLogo from "../images/GWLLogo.png";
function Footer({ logo, ...props }) {
  return (
    <Wrapper
      container
      justifyContent={{ xs: "space-between" }}
      alignItems={"center"}
      p={{ xs: 2, md: 4 }}
      pl={{ xs: 2, md: 10 }}
      flexWrap={"nowrap"}
      height="auto"
    >
      <img
        src={GWLLogo}
        style={{ width: 150, height: "auto" }}
        role="presentation"
        alt="GWL Realty Advisors"
      />{" "}
      <Link href="http://enrightcapital.com" target="Enright">
        <Logo />
      </Link>
    </Wrapper>
  );
}

const Wrapper = styled(Grid)`
  height: 125px;
  svg {
    // display: inline-block;
    // vertical-align: middle;
    width: 4.5em;
    height: auto;
    margin-right: 1rem;
    ${({ theme }) => theme.breakpoints.up("md")} {
      margin-right: 3rem;
      width: 4.5em;
      height: 5.5em;
    }
  }
  & > address {
    margin: 0.25em 1em 0.25em 0;
    display: inline-block;
    line-height: 1.75;
    text-align: right;
    p {
      margin: 0;
      font-size: 0.85rem;
      ${({ theme }) => theme.breakpoints.up("md")} {
        font-size: 1rem;
      }
    }
    h3 {
      font-size: 1em;
      line-height: 1.75;
      color: ${({ theme }) => theme.palette.primary.main};
      margin: 0;
      ${({ theme }) => theme.breakpoints.up("md")} {
        font-size: 1em;
        line-height: 1.75;
        color: ${({ theme }) => theme.palette.primary.main};
        margin: 0;
      }
    }
  }
`;

export default Footer;
